/* eslint-disable camelcase */
import FetchHelper from '@/helpers/FetchHelper'
import ObjectHelper from '@/helpers/ObjectHelper'
import UrlHelper from '@/helpers/UrlHelper'
import { loadStripe } from '@stripe/stripe-js'

const storageKeyId = process.env.BUILD_ID || process.env.NODE_ENV

let USD_PLANS = {
  subscription10: 'price_1Hs66eIFy1TaVEdHyzzG0sEX',
  subscription20: 'price_1Hs69NIFy1TaVEdHG4GLfNSg',
  subscription50: 'price_1Hs69cIFy1TaVEdHs6RqYTjM',
}

if (process.env.NODE_ENV !== 'production') {
  USD_PLANS = {
    subscription10: 'price_1HtJLLIFy1TaVEdHD92HoRJB',
    subscription20: 'price_1HtJLMIFy1TaVEdHsNqkH8Ih',
    subscription50: 'price_1HtJLMIFy1TaVEdHAxECF9k4',
  }
}

const CONFIG = {
  currencies: {
    GBP: {
      apiKey: process.env.GATSBY_STRIPE_PUBLIC_KEY_GBP,
      giftAid: true,
      locale: 'en-GB',
      plans: {
        subscription10: 'rpf-monthly-donation-10-gbp',
        subscription20: 'rpf-monthly-donation-20-gbp',
        subscription50: 'rpf-monthly-donation-50-gbp',
      },
      symbol: '£',
    },
    USD: {
      apiKey: process.env.GATSBY_STRIPE_PUBLIC_KEY_USD,
      giftAid: false,
      locale: 'en-US',
      plans: USD_PLANS,
      symbol: '$',
    },
    EUR: {
      apiKey: process.env.GATSBY_STRIPE_PUBLIC_KEY_EUR,
      giftAid: false,
      locale: 'en-GB',
      plans: {
        subscription10: 'rpf-monthly-donation-10-eur',
        subscription20: 'rpf-monthly-donation-20-eur',
        subscription50: 'rpf-monthly-donation-50-eur',
      },
      symbol: '€',
    },
  },

  modes: {
    payment: {
      defaultAmount: 20,
      defaultOption: 'payment20',
      label: 'One-time donation',
      options: {
        payment20: { amount: 20 },
        payment50: { amount: 50 },
        payment100: { amount: 100 },
        paymentOther: { amount: 200, label: 'Other amount' },
      },
    },
    subscription: {
      defaultAmount: 10,
      defaultOption: 'subscription10',
      label: 'Monthly donation',
      options: {
        subscription10: { amount: 10, label: 'a month' },
        subscription20: { amount: 20, label: 'a month' },
        subscription50: { amount: 50, label: 'a month' },
        subscriptionOther: { amount: 100, label: 'Other amount' },
      },
    },
  },

  storageKey: `rpf-donation-${storageKeyId}`,

  types: {
    personal: 'This is a personal donation',
    business: 'This donation is being made on behalf of a company',
  },

  urls: {
    cancel: UrlHelper.url('/donate/'),
    success: UrlHelper.url('/donate/thank-you/'),
  },
}

const DEFAULTS = {
  address_country: 'GB',
  amount: 20,
  companyName: '',
  currency: 'GBP',
  giftAid: false,
  mode: 'payment',
  option: 'payment20',
  type: 'personal',
}

const amountsForMode = (mode) => {
  const { options } = DonationHelper.modeData(mode)
  const amounts = Object.values(options).map((values) => values.amount)

  return amounts.slice(0, -1)
}

const createCheckoutSession = async (request) => {
  const url = UrlHelper.url(
    process.env.GATSBY_PAYMENTS_SERVICE_URL,
    '/checkout'
  )

  return await FetchHelper.post({ data: request, url })
}

const currencyData = (currency) => {
  return CONFIG.currencies[currency]
}

const formattedAmount = (amount, currency) => {
  const { locale } = DonationHelper.currencyData(currency)

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount)
}

const modeData = (mode) => {
  return CONFIG.modes[mode]
}

const optionKeyForAmount = (amount, mode) => {
  amount = parseInt(amount, 10)

  if (DonationHelper.predefinedAmount(amount, mode)) {
    return [mode, amount].join('')
  }

  return `${mode}Other`
}

const planId = (currency, option) => {
  return DonationHelper.currencyData(currency).plans[option]
}

const predefinedAmount = (amount, mode) => {
  const amounts = DonationHelper.amountsForMode(mode)
  return amounts.includes(amount)
}

const stripeApiKey = (currency) => {
  return DonationHelper.currencyData(currency).apiKey
}

const stripeCheckoutBaseData = (data) => {
  const { cancel, success } = CONFIG.urls

  return {
    currency: data.currency,
    mode: data.mode,
    metadata: ObjectHelper.omit(data, [
      'g-recaptcha-response',
      'h-captcha-response',
    ]),
    success_url: success,
    cancel_url: cancel,
    'g-recaptcha-response': data['g-recaptcha-response'],
    'h-captcha-response': data['h-captcha-response'],
  }
}

const stripeCheckoutData = (data) => {
  let baseData = DonationHelper.stripeCheckoutBaseData(data)
  let objectData

  if (data.mode === 'payment') {
    objectData = DonationHelper.stripePaymentData(data)
  } else {
    objectData = DonationHelper.stripeSubscriptionData(data)
  }

  return Object.assign({}, objectData, baseData)
}

const stripePaymentData = ({ amount, currency, type }) => {
  return {
    name: `Donate page: one-off ${type} donation`,
    amount: Math.round(amount * 100), // Rounding to deal with floating point precision
    currency,
    quantity: 1,
  }
}

const stripeSubscriptionData = ({ currency, option }) => {
  const plan = DonationHelper.planId(currency, option)

  return {
    /* eslint-disable-next-line camelcase */
    subscription_data: {
      items: [{ plan }],
    },
  }
}

const submitHandler = async (
  event,
  { setError, setProcessing, storeValues }
) => {
  event.preventDefault()
  const form = event.target

  setProcessing(true)

  if (!DonationHelper.validateForm(form)) {
    setError('Please fill out all required fields')
    setProcessing(false)
    return
  }

  try {
    const formData = new FormData(form)
    const data = Object.fromEntries(formData)
    storeValues(data)

    const apiKey = DonationHelper.stripeApiKey(data.currency)
    const stripe = await loadStripe(apiKey)

    const request = DonationHelper.stripeCheckoutData(data)
    const response = await DonationHelper.createCheckoutSession(request)

    stripe.redirectToCheckout({ sessionId: response.session_id })
  } catch (e) {
    setError(e)
    setProcessing(false)
  }
}

const validateForm = (form) => {
  const fields = form.querySelectorAll('[required]')

  return Array.from(fields).every((field) => {
    return field.validity.valid
  })
}

const DonationHelper = {
  CONFIG,
  DEFAULTS,
  amountsForMode,
  createCheckoutSession,
  currencyData,
  formattedAmount,
  modeData,
  optionKeyForAmount,
  planId,
  predefinedAmount,
  stripeApiKey,
  stripeCheckoutBaseData,
  stripeCheckoutData,
  stripePaymentData,
  stripeSubscriptionData,
  submitHandler,
  validateForm,
}

export default DonationHelper
