import './Label.scss'

import React, { useContext } from 'react'

import FormControlContext from '@/contexts/FormControlContext'

const Label = () => {
  const { id, label, optional } = useContext(FormControlContext)

  return (
    <label className="c-form-label" htmlFor={id}>
      {label}{' '}
      {optional && <span className="c-form-label__optional">(optional)</span>}
    </label>
  )
}
export default Label
