import { FormControlContextProvider } from '@/contexts/FormControlContext'
import OptionButton from '../OptionButton'
import PropTypes from 'prop-types'
import React from 'react'

const Checkbox = ({ children, name, required, ...rest }) => (
  <FormControlContextProvider name={name} required={required}>
    <OptionButton type="checkbox" {...rest}>
      {children}
    </OptionButton>
  </FormControlContextProvider>
)

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
}

export default Checkbox
