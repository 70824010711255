import { Checkbox, CheckboxGroup } from '@/components/shared/Form'

import { Link } from 'gatsby'
import React from 'react'

const MarketingConsent = () => {
  return (
    <CheckboxGroup legend="Join us">
      <p>
        Become part of the movement to bring digital making skills to young
        people all over the world. Occasionally hear about our latest
        achievements, campaigns and how you can support our life-changing work.
      </p>

      <Checkbox name="marketingConsentEmail" value={true}>
        I would like to receive updates <strong>by email</strong>
      </Checkbox>

      <Checkbox name="marketingConsentPost" value={true}>
        I would like to receive updates <strong>by post</strong>
      </Checkbox>

      <p>
        Your data will be processed in accordance with{' '}
        <Link className="sc-rp-link" to="/privacy/">
          the Raspberry Pi Foundation’s privacy statement
        </Link>
        .
      </p>
    </CheckboxGroup>
  )
}

export default MarketingConsent
