import './Amount.scss'

import React, { useContext } from 'react'

import Currency from './Currency'
import DonationContext from '@/contexts/DonationContext'
import { Fieldset } from '@/components/shared/Form'
import Input from './Input'
import Mode from './Mode'
import Options from './Options'

const Amount = () => {
  const { disabled, enabled } = useContext(DonationContext)

  return (
    <Fieldset>
      <div className="c-donate-amount">
        <div className="c-donate-amount__wrapper">
          <Mode />
          <Options />

          {enabled && (
            <div className="c-donate-amount__cashmoney">
              <div className="c-donate-amount__input">
                <Input />
              </div>

              <div className="c-donate-amount__currency">
                <Currency />
              </div>
            </div>
          )}

          {disabled && (
            <p>
              We can set up a different monthly donation amount for you — just
              get in touch with us at{' '}
              <a className="sc-rp-link" href="mailto:partners@raspberrypi.org">
                partners@raspberrypi.org
              </a>
            </p>
          )}
        </div>
      </div>
    </Fieldset>
  )
}

export default Amount
