import './Textarea.scss'

import React, { useContext } from 'react'

import FormContext from '@/contexts/FormContext'
import { FormControlContextProvider } from '@/contexts/FormControlContext'
import FormGroup from '../FormGroup'
import PropTypes from 'prop-types'

const Textarea = ({ label, name, required, ...rest }) => {
  const { changeHandler, defaultValue } = useContext(FormContext)

  return (
    <FormControlContextProvider label={label} name={name} required={required}>
      <FormGroup>
        <textarea
          className="c-form-textarea"
          defaultValue={defaultValue(name)}
          onChange={changeHandler}
          {...rest}
        />
      </FormGroup>
    </FormControlContextProvider>
  )
}

Textarea.defaultProps = {
  required: false,
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default Textarea
