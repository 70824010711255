import ApplicationLayout from '@/Layouts/Application'
import Donate from '@/components/Pages/Donate'
import { DonationContextProvider } from '@/contexts/DonationContext'
import DonationHelper from '@/helpers/DonationHelper'
import PageMeta from '@/components/Application/PageMeta'
import React from 'react'
import opengraphImageSrc from '@/pages/donate/images/opengraph.png'
import useSessionStorage from '@/hooks/useSessionStorage'

const DonatePage = () => {
  const title = 'Donate to the Raspberry Pi Foundation'
  const description =
    'Through your donation, you give young people the opportunity to change their world using technology.'

  const [values, storeValues] = useSessionStorage(
    DonationHelper.CONFIG.storageKey,
    DonationHelper.DEFAULTS
  )

  return (
    <ApplicationLayout>
      <PageMeta
        description={description}
        image={opengraphImageSrc}
        title={title}
      />

      <DonationContextProvider values={values}>
        <Donate values={values} storeValues={storeValues} />
      </DonationContextProvider>
    </ApplicationLayout>
  )
}

export default DonatePage
