import './Input.scss'

import React, { useContext } from 'react'

import FormContext from '@/contexts/FormContext'
import { FormControlContextProvider } from '@/contexts/FormControlContext'
import FormGroup from '../FormGroup'
import PropTypes from 'prop-types'

const Input = ({ label, name, type, required, ...rest }) => {
  const { changeHandler, defaultValue } = useContext(FormContext)

  return (
    <FormControlContextProvider label={label} name={name} required={required}>
      <FormGroup>
        <input
          className="c-form-input"
          defaultValue={defaultValue(name)}
          onChange={changeHandler}
          type={type}
          {...rest}
        />
      </FormGroup>
    </FormControlContextProvider>
  )
}

export const validTypes = [
  'button',
  'color',
  'date',
  'datetime',
  'email',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'range',
  'reset',
  'search',
  'submit',
  'tel',
  'text',
  'time',
  'url',
  'week',
]

Input.defaultProps = {
  type: 'text',
  required: false,
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(validTypes),
  required: PropTypes.bool,
}

export default Input
