import './YourDetails.scss'

import {
  Fieldset,
  Input,
  Option,
  RadioButton,
  RadioGroup,
  Select,
} from '@/components/shared/Form'
import React, { useContext } from 'react'
import { Link } from 'gatsby'

import DonationContext from '@/contexts/DonationContext'
import DonationHelper from '@/helpers/DonationHelper'
import FormContext from '@/contexts/FormContext'
import countryList from 'react-select-country-list'
import giftAidLogo from './images/gift-aid-logo.svg'

const countries = countryList().getData()

const YourDetails = () => {
  const { amount, currency, currencyData } = useContext(DonationContext)
  const { values } = useContext(FormContext)

  const giftAidEnabled = currencyData.giftAid && values.type === 'personal'
  const showAddressFields = giftAidEnabled && values.giftAid

  // eslint-disable-next-line no-magic-numbers
  const amountWithGiftAid = amount * 1.25

  const showUSDisclosures = currency === 'USD'

  return (
    <div className="c-donate-your-details">
      {giftAidEnabled && (
        <Fieldset>
          <h2 id="gift-aid-heading">
            <img
              alt="Gift Aid"
              className="c-donate-your-details__gift-aid-logo"
              src={giftAidLogo}
            />
          </h2>

          <p className="c-donate-your-details__lede">
            Your donation of{' '}
            <span className="c-donate-your-details__amount">
              {DonationHelper.formattedAmount(amount, currency)}
            </span>{' '}
            will be increased to{' '}
            <span className="c-donate-your-details__amount">
              {DonationHelper.formattedAmount(amountWithGiftAid, currency)}
            </span>{' '}
            with Gift Aid with no extra cost to you.
          </p>

          <p>
            Gift Aid is a government scheme for UK charities to reclaim the tax
            you have paid and gain 25%.
          </p>

          <RadioGroup aria-labelledby="gift-aid-heading" name="giftAid">
            <RadioButton required={true} value={true}>
              <p>
                I’d like the Raspberry Pi Foundation to claim Gift Aid on this
                donation, as well as any future donations and any donations made
                in the past four years.
              </p>

              <p>
                I am a UK taxpayer. I understand that if I pay less Income Tax
                and/or Capital Gains Tax in the current tax year than the amount
                of Gift Aid claimed on my donations I must pay the difference.
              </p>
            </RadioButton>

            <RadioButton name="giftAid" required={true} value={false}>
              I do not wish to claim Gift Aid or I am not a UK taxpayer.
            </RadioButton>
          </RadioGroup>
        </Fieldset>
      )}

      <Fieldset legend="Your details">
        <Input label="Email" name="email" type="email" required={true} />
        <Input label="First name" name="first_name" required={true} />
        <Input label="Last name" name="last_name" required={true} />
      </Fieldset>

      {showUSDisclosures && (
        <Fieldset>
          <p>
            <Link
              className="sc-rp-link"
              href="https://static.raspberrypi.org/files/US+Charity+and+Non-Profit+Disclosures_DR.pdf"
            >
              View our US Charitable and Non-Profit Disclosures
            </Link>
          </p>
        </Fieldset>
      )}

      {showAddressFields && (
        <Fieldset legend="Your home address">
          <Input label="Address line 1" name="address_line1" required={true} />
          <Input label="Address line 2" name="address_line2" />
          <Input label="Town/city" name="address_city" required={true} />
          <Input label="County/state/region" name="address_state" />
          <Input label="Post code" name="address_postal_code" required={true} />

          <Select
            label="Country or region"
            name="address_country"
            required={true}
          >
            {Object.entries(countries).map(([id, country]) => (
              <Option key={`country-${id}`} value={country.value}>
                {country.label}
              </Option>
            ))}
          </Select>
        </Fieldset>
      )}
    </div>
  )
}

export default YourDetails
