import './Impact.scss'

import React, { useContext } from 'react'

import DonationContext from '@/contexts/DonationContext'
import payment100image from './images/payment/payment100.jpg'
import payment20image from './images/payment/payment20.jpg'
import payment50image from './images/payment/payment50.jpg'
import paymentOtherimage from './images/payment/paymentOther.jpg'
import subscription10image from './images/subscription/subscription10.jpg'
import subscription20image from './images/subscription/subscription20.jpg'
import subscription50image from './images/subscription/subscription50.jpg'
import subscriptionOtherimage from './images/subscription/subscriptionOther.jpg'

const impacts = {
  payment20: {
    image: payment20image,
    caption: `With <strong>[CURRENCY_SYMBOL]20</strong> you will support 60 young learners to get creative with an online coding project.`,
  },
  payment50: {
    image: payment50image,
    caption: `<strong>With [CURRENCY_SYMBOL]50</strong> you will support 200 young people to complete an online coding project.`,
  },
  payment100: {
    image: payment100image,
    caption: `<strong>With [CURRENCY_SYMBOL]100</strong> you will support the setup of a new coding club for young people.`,
  },
  paymentOther: {
    image: paymentOtherimage,
    caption: `Your donation will empower young people to become confident, creative digital makers.`,
  },
  subscription10: {
    image: subscription10image,
    caption: `<strong>With [CURRENCY_SYMBOL]10</strong> per month you will support 12 young people to attend a free coding club for a whole year. `,
  },
  subscription20: {
    image: subscription20image,
    caption: `<strong>With [CURRENCY_SYMBOL]20</strong> per month you will support 24 young people to attend a free coding club for a whole year. `,
  },
  subscription50: {
    image: subscription50image,
    caption: `With <strong>[CURRENCY_SYMBOL]50</strong> a month, you will support 1800 young learners to get creative with an online coding project in the year ahead.`,
  },
  subscriptionOther: {
    image: subscriptionOtherimage,
    caption: `Your donation will empower young people to become confident, creative digital makers.`,
  },
}

const Impact = () => {
  const { currencyData, option } = useContext(DonationContext)

  const impact = impacts[option]
  const { image, caption } = impact

  return (
    <figure className="c-donate-impact">
      <img
        alt=""
        className="c-donate-impact__image"
        height="313"
        src={image}
        width="470"
      />
      {caption && (
        <figcaption
          className="c-donate-impact__caption"
          dangerouslySetInnerHTML={{
            __html: caption.replace('[CURRENCY_SYMBOL]', currencyData.symbol),
          }}
        />
      )}
    </figure>
  )
}

export default Impact
