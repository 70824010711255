import React, { useContext } from 'react'

import FormContext from '@/contexts/FormContext'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const Captcha = () => {
  const { captchaRef, setCaptchaToken } = useContext(FormContext)

  return (
    <HCaptcha
      onVerify={setCaptchaToken}
      ref={captchaRef}
      sitekey={process.env.GATSBY_HCAPTCHA_SITEKEY}
    />
  )
}

export default Captcha
