import { FormControlContextProvider } from '@/contexts/FormControlContext'
import OptionButton from '../OptionButton'
import PropTypes from 'prop-types'
import React from 'react'

const RadioButton = ({ children, name, required, value, ...rest }) => (
  <FormControlContextProvider name={name} required={required}>
    <OptionButton type="radio" value={value} {...rest}>
      {children}
    </OptionButton>
  </FormControlContextProvider>
)

RadioButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
}

export default RadioButton
