import './DonationType.scss'

import {
  Fieldset,
  Input,
  RadioButton,
  RadioGroup,
} from '@/components/shared/Form'
import React, { useContext } from 'react'

import DonationHelper from '@/helpers/DonationHelper'
import FormContext from '@/contexts/FormContext'

const DonationType = () => {
  const { types } = DonationHelper.CONFIG
  const { values } = useContext(FormContext)

  const showCompanyName = values.type === 'business'

  return (
    <Fieldset className="c-donate-type" legend="Donation type">
      <RadioGroup className="c-donate-type__radio-group" name="type">
        {Object.entries(types).map(([value, label]) => {
          return (
            <RadioButton
              className="c-donate-type__radio-button"
              key={value}
              required={true}
              value={value}
            >
              {label}
            </RadioButton>
          )
        })}
      </RadioGroup>

      {showCompanyName && (
        <Input label="Company name" name="companyName" required />
      )}
    </Fieldset>
  )
}

export default DonationType
