import './Mode.scss'

import React, { useContext } from 'react'

import DonationContext from '@/contexts/DonationContext'
import DonationHelper from '@/helpers/DonationHelper'

const Mode = () => {
  const { mode, modeHandler } = useContext(DonationContext)
  const { modes } = DonationHelper.CONFIG

  return (
    <div className="c-donate-mode">
      <h2 className="c-donate-mode__heading">I would like to make a</h2>

      <ul className="c-donate-mode__list">
        {Object.entries(modes).map(([key, values]) => {
          return (
            <li className="c-donate-mode__item" key={`mode-${key}`}>
              <label className="c-donate-mode__label">
                <input
                  className="c-form-option-button__input c-form-option-button__input--radio"
                  defaultChecked={mode === key}
                  name="mode"
                  onChange={modeHandler}
                  required
                  type="radio"
                  value={key}
                />{' '}
                {values.label}
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Mode
