/* eslint-disable no-console */

const defaultHeaders = {
  'Content-Type': 'application/json',
}

const defaultOptions = {
  method: 'GET',
}

const get = ({ options, headers, url }) =>
  FetchHelper.perform({
    headers,
    options: Object.assign({}, options, defaultOptions),
    url,
  })

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText)
  }

  return response
}

const perform = ({ data, headers, options, url }) => {
  const fetchOptions = FetchHelper.requestOptions({ data, headers, options })

  return fetch(url, fetchOptions)
    .then(handleErrors)
    .then((response) => {
      if (response.headers.get('Content-type').includes('application/json')) {
        return response.json()
      }

      return response.text()
    })
    .catch((error) => {
      console.error(error.toString())
      return error
    })
}

const post = ({ data, options, headers, url }) =>
  FetchHelper.perform({
    data,
    headers,
    options: Object.assign({}, options, { method: 'POST' }),
    url,
  })

const requestBody = (data, headers) => {
  if (headers['Content-Type'] === 'application/json') {
    return JSON.stringify(data)
  }

  return data
}

const requestOptions = ({ data, headers, options }) => {
  headers = Object.assign({}, FetchHelper.defaultHeaders, headers)
  options = Object.assign({}, FetchHelper.defaultOptions, options, {
    body: requestBody(data, headers),
    headers,
  })

  if (['GET', 'HEAD'].includes(options.method)) {
    delete options.body
  }

  return options
}

const FetchHelper = {
  defaultHeaders,
  defaultOptions,
  get,
  handleErrors,
  perform,
  post,
  requestBody,
  requestOptions,
}

export default FetchHelper
