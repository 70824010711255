const changeHandler = (event, { setValues, values }) => {
  const { target } = event
  const { checked, name, value, type } = target

  let updatedValue = type === 'checkbox' ? checked : value

  if (updatedValue === 'true' || updatedValue === 'false') {
    updatedValue = updatedValue === 'true'
  }

  const newValues = Object.assign({}, values, {
    [name]: updatedValue,
  })

  setValues(newValues)

  return newValues
}

const defaultChecked = (name, value, { initialValues }) => {
  if (typeof value === 'undefined') {
    value = true
  }

  return FormContextHelper.defaultValue(name, { initialValues }) === value
}

const defaultSelected = (name, { initialValues }) => {
  return FormContextHelper.defaultValue(name, { initialValues }) || ''
}

const defaultValue = (name, { initialValues }) => {
  return initialValues[name] || null
}

const FormContextHelper = {
  changeHandler,
  defaultChecked,
  defaultSelected,
  defaultValue,
}

export default FormContextHelper
