import './Fieldset.scss'

import PropTypes from 'prop-types'
import React from 'react'
import cx from 'bem-classnames'

const Fieldset = ({ children, className, legend, ...rest }) => {
  const classNames = cx(['c-form-fieldset__wrapper', className])

  return (
    <div className={classNames}>
      <fieldset className="c-form-fieldset__element" {...rest}>
        {legend && (
          <legend className="c-form-fieldset__legend">{legend}</legend>
        )}
        {children}
      </fieldset>
    </div>
  )
}

Fieldset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  legend: PropTypes.string,
}

export default Fieldset
