// Adapted from https://usehooks.com/useLocalStorage/
import { useEffect, useState } from 'react'

const useWebStorageApi = (storageApi, key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = storageApi.getItem(key)

    if (item) {
      return JSON.parse(item)
    }

    return initialValue
  })

  const setValue = (newValue) => {
    let updatedValue = newValue

    if (newValue instanceof Function) {
      updatedValue = newValue(storedValue)
    }

    setStoredValue(updatedValue)
  }

  useEffect(() => {
    storageApi.setItem(key, JSON.stringify(storedValue))
  }, [key, storageApi, storedValue])

  return [storedValue, setValue]
}

export default useWebStorageApi
