import React, { Children } from 'react'

import Fieldset from '../Fieldset'
import PropTypes from 'prop-types'

const RadioGroup = ({ children, legend, name, required, ...rest }) => (
  <Fieldset legend={legend} {...rest}>
    {Children.map(children, (child, index) => (
      <child.type
        key={index}
        name={name}
        required={required}
        {...child.props}
      />
    ))}
  </Fieldset>
)

RadioGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  legend: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default RadioGroup
