import React, { createContext } from 'react'

import PropTypes from 'prop-types'
import useDonation from '@/hooks/useDonation'

const DonationContext = createContext()

export const DonationContextProvider = ({ children, values }) => {
  const context = useDonation(values)

  return (
    <DonationContext.Provider value={context}>
      {children}
    </DonationContext.Provider>
  )
}

DonationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  values: PropTypes.object.isRequired,
}

export default DonationContext
