import { useEffect, useState } from 'react'

import DonationHelper from '@/helpers/DonationHelper'

const useDonation = (values) => {
  const [amount, setAmount] = useState(values.amount)
  const [currency, setCurrency] = useState(values.currency)
  const [error, setError] = useState()
  const [mode, setMode] = useState(values.mode)
  const [option, setOption] = useState(values.option)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (typeof amount !== 'number') {
      setAmount(parseFloat(amount))
    }
  }, [amount])

  const amountHandler = (event) => {
    const { value } = event.target

    let optionKey = DonationHelper.optionKeyForAmount(value, context.mode)

    context.setOption(optionKey)
    context.setAmount(value)
  }

  const currencyHandler = (event) => {
    const { value } = event.target
    context.setCurrency(value.toUpperCase())
  }

  const modeHandler = (event) => {
    const { value } = event.target
    const { defaultAmount, defaultOption } = DonationHelper.modeData(value)

    context.setAmount(defaultAmount)
    context.setOption(defaultOption)
    context.setMode(value)
  }

  const optionHandler = (event) => {
    const { value } = event.target

    if (!value.endsWith('Other')) {
      const { options } = context.modeData
      const optionData = options[value]

      context.setAmount(optionData.amount)
    }

    context.setOption(value)
  }

  const context = {
    amount,
    amountHandler,
    get amountWithGiftAid() {
      // eslint-disable-next-line no-magic-numbers
      return this.amount * 1.25
    },
    currency,
    get currencyData() {
      return DonationHelper.currencyData(context.currency)
    },
    currencyHandler,
    get disabled() {
      return this.mode === 'subscription' && this.option.endsWith('Other')
    },
    get enabled() {
      return !this.disabled
    },
    error,
    mode,
    get modeData() {
      return DonationHelper.modeData(context.mode)
    },
    modeHandler,
    option,
    optionHandler,
    processing,
    setAmount,
    setCurrency,
    setError,
    setMode,
    setOption,
    setProcessing,
  }

  return context
}

export default useDonation
