import './Input.scss'

import React, { useContext } from 'react'

import DonationContext from '@/contexts/DonationContext'

const Input = () => {
  const {
    amount,
    currency,
    currencyData,
    amountHandler,
    mode,
    modeData,
    option,
  } = useContext(DonationContext)

  const inputDisabled = mode === 'subscription'
  const { symbol } = currencyData

  let placeholder = 0
  let value = amount

  if (inputDisabled) {
    const label = modeData.options[option].label
    placeholder = `${symbol}${amount} ${label}`
    value = ''
  }

  return (
    <div className="c-donate-input">
      <label
        className="c-donate-input__label"
        data-sauce-screen-reader-only="true"
        htmlFor="c-donate-input__amount"
      >
        Donation Amount
      </label>

      <span
        className={`c-donate-input__currency-symbol c-donate-input__currency-symbol--${mode}`}
      >
        {symbol}
      </span>

      <input
        className={`c-form-input c-donate-input__amount c-donate-input__amount--${mode}`}
        disabled={inputDisabled}
        id="c-donate-input__amount"
        name="amount"
        onChange={amountHandler}
        placeholder={placeholder}
        min="1"
        step="0.01"
        type="number"
        value={value}
      />
    </div>
  )
}

export default Input
