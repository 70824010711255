import './Submit.scss'

import React, { useContext } from 'react'

import Captcha from '../Captcha'
import FormContext from '@/contexts/FormContext'
import PropTypes from 'prop-types'
import { isBrowser } from '@/helpers/WindowHelper'

const Submit = ({ label, ...rest }) => {
  const { submitDisabled, withCaptcha } = useContext(FormContext)
  const renderCaptcha = withCaptcha && isBrowser

  return (
    <div className="c-form-submit">
      {renderCaptcha && (
        <div className="c-form-submit__captcha">
          <Captcha />
        </div>
      )}

      <button className="sc-rp-button" disabled={submitDisabled} {...rest}>
        {label}
      </button>
    </div>
  )
}

Submit.defaultProps = {
  label: 'Submit',
}

Submit.propTypes = {
  label: PropTypes.string,
}

export default Submit
