import PropTypes from 'prop-types'
import React from 'react'

const Option = ({ children, value, ...rest }) => (
  <option value={value} {...rest}>
    {children}
  </option>
)

Option.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Option
