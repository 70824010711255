/* eslint-disable react/jsx-no-target-blank */
import './Hero.scss'

import React from 'react'

const Hero = () => {
  return (
    <header className="c-donate-hero">
      <div className="c-donate-hero__content">
        <h1 className="c-donate-hero__heading">Donate</h1>

        <p className="c-donate-hero__copy">
          Your donation gives young people access to free computing education.
          Learning to create with digital technology opens a world of
          possibilities for them and their communities.
        </p>

        <p className="c-donate-hero__copy">
          Thank you for supporting our work to provide world-class resources and
          support for educators and learners around the globe.
        </p>
      </div>
    </header>
  )
}

export default Hero
