import React, { createContext } from 'react'

import PropTypes from 'prop-types'

export const FormControlContextProvider = ({
  children,
  label,
  name,
  required,
}) => {
  const id = `${name}-field-id`
  const optional = !required

  const contextValue = {
    id,
    label,
    name,
    optional,
    required,
  }

  return (
    <FormControlContext.Provider value={contextValue}>
      {children}
    </FormControlContext.Provider>
  )
}

FormControlContextProvider.defaultProps = {
  required: false,
}

FormControlContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  optional: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

const FormControlContext = createContext({
  id: '',
  label: '',
  name: '',
  optional: true,
  required: false,
})

export default FormControlContext
