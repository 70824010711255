import './FormGroup.scss'

import React, { Children, useContext } from 'react'

import FormControlContext from '@/contexts/FormControlContext'
import Label from '../Label'
import PropTypes from 'prop-types'

const FormGroup = ({ children }) => {
  const { id, name, required } = useContext(FormControlContext)

  return (
    <div className="c-form-group">
      <Label />

      {Children.map(children, (child) => (
        <child.type id={id} name={name} required={required} {...child.props} />
      ))}
    </div>
  )
}

FormGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}

export default FormGroup
