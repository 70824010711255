import './Error.scss'

import PropTypes from 'prop-types'
import React from 'react'

const Error = ({ heading, intro, message }) => {
  if (!message) {
    return null
  }

  return (
    <div className="c-form-error">
      {heading && <h2 className="c-form-error__heading">Error</h2>}
      {intro && <p className="c-form-error__intro">{intro}</p>}
      <p className="c-form-error__message">{message}</p>
    </div>
  )
}

Error.propTypes = {
  heading: PropTypes.string,
  intro: PropTypes.string,
  message: PropTypes.string,
}

export default Error
