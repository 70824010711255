import './Form.scss'

import Checkbox from './Checkbox'
import CheckboxGroup from './CheckboxGroup'
import Error from './Error'
import Fieldset from './Fieldset'
import { FormContextProvider } from '@/contexts/FormContext'
import Input from './Input'
import Option from './Option'
import PropTypes from 'prop-types'
import RadioButton from './RadioButton'
import RadioGroup from './RadioGroup'
import React from 'react'
import Select from './Select'
import Submit from './Submit'
import Textarea from './Textarea'
import cx from 'bem-classnames'

const Form = ({
  children,
  className,
  initialValues,
  submitHandler,
  withCaptcha,
  ...rest
}) => {
  const classNames = cx(['c-form', className])

  return (
    <FormContextProvider
      initialValues={initialValues}
      submitHandler={submitHandler}
      withCaptcha={withCaptcha}
    >
      <form className={classNames} {...rest}>
        {children}
      </form>
    </FormContextProvider>
  )
}

Form.defaultProps = {
  initialValues: {},
  withCaptcha: false,
}

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  submitHandler: PropTypes.func,
  withCaptcha: PropTypes.bool,
}

export {
  Checkbox,
  CheckboxGroup,
  Error,
  Fieldset,
  Input,
  Submit,
  RadioButton,
  RadioGroup,
  Select,
  Option,
  Textarea,
}

export default Form
