import './Select.scss'

import React, { useContext } from 'react'

import FormContext from '@/contexts/FormContext'
import { FormControlContextProvider } from '@/contexts/FormControlContext'
import FormGroup from '../FormGroup'
import Option from '../Option'
import PropTypes from 'prop-types'

const Select = ({ children, label, name, required, ...rest }) => {
  const { changeHandler, defaultSelected } = useContext(FormContext)

  return (
    <FormControlContextProvider label={label} name={name} required={required}>
      <FormGroup>
        <select
          className="c-form-select"
          defaultValue={defaultSelected(name)}
          onBlur={changeHandler}
          {...rest}
        >
          {children}
        </select>
      </FormGroup>
    </FormControlContextProvider>
  )
}

Select.propTypes = {
  children: PropTypes.arrayOf(Option).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default Select
