import './OptionButton.scss'

import React, { useContext, useRef } from 'react'

import FormContext from '@/contexts/FormContext'
import FormControlContext from '@/contexts/FormControlContext'
import PropTypes from 'prop-types'
import cx from 'bem-classnames'

const OptionButton = ({
  children,
  className,
  type,
  value,
  otherInputName,
  ...rest
}) => {
  const classNames = cx(['c-form-option-button', className])
  const { name, required } = useContext(FormControlContext)
  const { changeHandler, defaultChecked, defaultValue } = useContext(
    FormContext
  )

  const inputClassNameConfig = {
    modifiers: ['checkbox', 'radio'],
    name: 'c-form-option-button__input',
  }

  const inputClassName = cx(inputClassNameConfig, {
    checkbox: type === 'checkbox',
    radio: type === 'radio',
  })

  const optionButtonEl = useRef(null)

  const otherChangeHandler = (...args) => {
    // optionButtonEl.checked = true
    optionButtonEl.current.checked = true
    changeHandler(...args)
  }

  return (
    <label className={classNames}>
      <input
        className={inputClassName}
        defaultChecked={defaultChecked(name, value)}
        name={name}
        onChange={changeHandler}
        ref={optionButtonEl}
        required={required}
        type={type}
        value={value}
        {...rest}
      />

      <div className="c-form-option-button__children">
        {children}
        {otherInputName && (
          <input
            className="c-form-input c-form-input--other"
            defaultValue={defaultValue(otherInputName)}
            name={otherInputName}
            onChange={otherChangeHandler}
            type="text"
          />
        )}
      </div>
    </label>
  )
}

OptionButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  otherInputName: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
}

export default OptionButton
