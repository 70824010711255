import './Donate.scss'

import Form, { Error, Submit } from '@/components/shared/Form'
import React, { useContext } from 'react'

import Amount from './Amount'
import DonationContext from '@/contexts/DonationContext'
import DonationHelper from '@/helpers/DonationHelper'
import DonationType from './DonationType'
import Hero from './Hero'
import Impact from './Impact'
import MarketingConsent from './MarketingConsent'
import PropTypes from 'prop-types'
import YourDetails from './YourDetails'

const Donate = ({ storeValues, values }) => {
  const { enabled, error, processing, setError, setProcessing } = useContext(
    DonationContext
  )

  const submitHandler = (event) => {
    DonationHelper.submitHandler(event, {
      setError,
      setProcessing,
      storeValues,
    })
  }

  let buttonLabel = 'Proceed to payment'

  if (processing) {
    buttonLabel = 'Processing'
  }

  return (
    <div className="c-donate">
      <Hero />

      <div className="c-donate__wrapper">
        <div className="c-donate__impact">
          <Impact />
        </div>

        <Form
          className="c-donate__form"
          initialValues={values}
          submitHandler={submitHandler}
          withCaptcha={true}
        >
          <Amount />

          {enabled && (
            <>
              <DonationType />
              <YourDetails />
              <MarketingConsent />
              <Error
                heading="Error"
                intro="We encountered a problem processing your donation:"
                message={error}
              />
              <Submit disabled={processing} label={buttonLabel} />
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

Donate.propTypes = {
  storeValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default Donate
