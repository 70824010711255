import React, { useContext } from 'react'

import DonationContext from '@/contexts/DonationContext'
import DonationHelper from '@/helpers/DonationHelper'

const Currency = () => {
  const { currency, currencyHandler } = useContext(DonationContext)
  const { currencies } = DonationHelper.CONFIG

  return (
    <div className="c-donate-currency">
      <label
        className="c-donate-currency__label"
        data-sauce-screen-reader-only="true"
        htmlFor="c-donate-currency__select"
      >
        Currency
      </label>

      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        className="c-form-select c-donate-currency__select"
        id="c-donate-currency__select"
        name="currency"
        onChange={currencyHandler}
        required
        value={currency}
      >
        {Object.keys(currencies).map((value) => (
          <option key={`option-${value}`} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Currency
