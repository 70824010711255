import './Options.scss'

import React, { useContext } from 'react'

import DonationContext from '@/contexts/DonationContext'

const Options = () => {
  const { currencyData, option, optionHandler, modeData } = useContext(
    DonationContext
  )
  const { options } = modeData
  const { symbol } = currencyData

  return (
    <ul className="c-donate-options">
      {Object.entries(options).map(([key, values]) => {
        const isCurrency = !key.endsWith('Other')
        const { amount, label } = values

        return (
          <li className="c-donate-options__item" key={`option-${key}`}>
            <label className="c-donate-options__label">
              <input
                className="c-donate-options__radio"
                checked={option === key}
                data-sauce-screen-reader-only="true"
                name="option"
                onChange={optionHandler}
                required
                type="radio"
                value={key}
              />{' '}
              <span className="c-donate-options__button">
                {isCurrency && [symbol, amount].join('')}
                {label && (
                  <span className="c-donate-options__button-label">
                    {label}
                  </span>
                )}
              </span>
            </label>
          </li>
        )
      })}
    </ul>
  )
}

export default Options
