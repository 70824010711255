import React, { Children, createContext } from 'react'

import PropTypes from 'prop-types'
import useFormContext from '@/hooks/useFormContext'

const FormContext = createContext()

export const FormContextProvider = ({
  children,
  initialValues,
  submitHandler,
  withCaptcha,
}) => {
  const context = useFormContext({ initialValues, submitHandler, withCaptcha })
  const onSubmit = submitHandler && context.onSubmit

  return (
    <FormContext.Provider value={context}>
      {Children.map(children, (child) => (
        <child.type onSubmit={onSubmit} {...child.props} />
      ))}
    </FormContext.Provider>
  )
}

FormContextProvider.propTypes = {
  children: PropTypes.shape({
    type: PropTypes.oneOf(['form']),
  }).isRequired,
  initialValues: PropTypes.object,
  submitHandler: PropTypes.func,
  withCaptcha: PropTypes.bool,
}

export default FormContext
