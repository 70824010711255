import Fieldset from '../Fieldset'
import PropTypes from 'prop-types'
import React from 'react'

const CheckboxGroup = ({ children, legend, ...rest }) => (
  <Fieldset legend={legend} {...rest}>
    {children}
  </Fieldset>
)

CheckboxGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  legend: PropTypes.string,
}

export default CheckboxGroup
