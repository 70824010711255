import { useEffect, useRef, useState } from 'react'

import FormContextHelper from '@/helpers/FormContextHelper'

const useFormContext = ({ initialValues, submitHandler, withCaptcha }) => {
  const captchaRef = useRef()
  const [captchaToken, setCaptchaToken] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [result, setResult] = useState()
  const [values, setValues] = useState({})

  const captchaError = {
    success: false,
    errors: {
      captcha: ['must be verified'],
    },
  }

  useEffect(() => {
    setIsSubmitting(false)
  }, [result])

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  const changeHandler = (event) =>
    FormContextHelper.changeHandler(event, { setValues, values })
  const defaultChecked = (name, value) =>
    FormContextHelper.defaultChecked(name, value, { initialValues })
  const defaultSelected = (name) =>
    FormContextHelper.defaultSelected(name, { initialValues })
  const defaultValue = (name) =>
    FormContextHelper.defaultValue(name, { initialValues })

  const onSubmit = (event) => {
    event.preventDefault()
    context.setIsSubmitting(true)

    if (context.verifyCaptcha()) {
      const submitHandlerResult = context.submitHandler(event)
      context.setResult(submitHandlerResult)
    }
  }

  const verifyCaptcha = () => {
    if (!context.withCaptcha) {
      return true
    }

    if (!context.captchaToken) {
      context.setResult(context.captchaError)
    }

    return context.captchaToken
  }

  const context = {
    captchaError,
    captchaRef,
    captchaToken,
    changeHandler,
    defaultChecked,
    defaultSelected,
    defaultValue,
    initialValues,
    isSubmitting,
    onSubmit,
    result,
    setCaptchaToken,
    setIsSubmitting,
    setResult,
    setValues,
    get submitDisabled() {
      return this.isSubmitting || (this.withCaptcha && !context.captchaToken)
    },
    submitHandler,
    values,
    verifyCaptcha,
    withCaptcha,
  }

  return context
}

export default useFormContext
